import { Button, Col, Descriptions, Divider, Row } from 'antd';
import React, { FC } from 'react';
import { AppointmentDetailsDisplayType, AppointmentsDetailsCard } from './AppointmentsDetailsCard';
import { AttachmentsCard } from './AttachmentsCard';
import { ReferralData, QuestionnaireAnswerSet } from './GetReferralQuery';
import { ResultsCard } from './ResultsCard';
import { VisitType } from './VisitType';
import { questionnaireSaveAsPDF } from '../../../appointments/components/QuestionnaireSaveAsPDF';

export const ReferralDetailsCard: FC<{ referral: ReferralData }> = ({ referral }): JSX.Element => {
  let notes = null;
  let answers = null;
  let questionnaireAnswers = null;

  if (referral.description) {
    const notesData = referral.description.split('Questionnaire Answers:\n');

    notes = notesData[0];
    answers = notesData.slice(1);
  }

  if (referral.questionnaireAnswerSets && referral.questionnaireAnswerSets.length > 0) {
    questionnaireAnswers = referral.questionnaireAnswerSets;
  }

  return (
    <>
      <Row>
        <Col xs={24} xl={14}>
          <VisitType referral={referral}></VisitType>
          <Row style={{ marginTop: '16px' }}>
            <Col span={24}>
              <h4>Description</h4>
              <div>
                <p>
                  {notes?.trim() ? (
                    notes.split('\n').map((item, key) => (
                      <span key={key}>
                        {item}
                        <br />
                      </span>
                    ))
                  ) : (
                    <i className="text-grey-dark text-xs">No description has been added.</i>
                  )}
                </p>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={24} xl={{ span: 9, push: 1 }} className="mt-8 mb-4 xl:mt-0 xl:mb-0">
          <Row>
            <AttachmentsCard referral={referral} />
          </Row>
          <Divider />
          <Row style={{ marginTop: 8 }}>
            {referral.tags.length > 0 ? (
              <table
                style={{
                  borderCollapse: 'collapse',
                  width: '100%',
                  tableLayout: 'fixed',
                }}
              >
                <tbody>
                  {referral.tags.map(({ key, value }) => (
                    <tr key={key}>
                      <td
                        style={{
                          fontFamily: 'monospace',
                          fontSize: 12,
                          fontWeight: 800,
                          padding: '8px',
                          border: '1px solid #ddd',
                          whiteSpace: 'normal',
                          backgroundColor: '#fafafa',
                          wordBreak: 'break-word',
                        }}
                      >
                        {key}
                      </td>
                      <td
                        style={{
                          padding: '8px',
                          border: '1px solid #ddd',
                          overflowX: 'auto',
                          maxWidth: '300px',
                          wordWrap: 'break-word',
                          overflowWrap: 'break-word',
                        }}
                      >
                        <div style={{ overflowX: 'auto' }}>{value}</div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : null}
          </Row>
        </Col>
        {!questionnaireAnswers && Array.isArray(answers) && answers.length > 0 && (
          <DescriptionAnswers answers={answers} referral={referral} />
        )}
        {questionnaireAnswers && <QuestionnaireAnswers sets={referral.questionnaireAnswerSets} referral={referral} />}
      </Row>
      <Row>
        <Col span={24}>
          <Divider orientation="left">Appointments</Divider>
          <AppointmentsDetailsCard
            referral={referral}
            appointment={referral.appointment}
            cardType={AppointmentDetailsDisplayType.Referral}
          />
        </Col>
      </Row>
      <ResultsCard referral={referral} />
    </>
  );
};

const DescriptionAnswers: FC<{ answers: string[]; referral: ReferralData }> = ({ answers, referral }) => {
  return (
    <Col span={24}>
      {Array.isArray(answers) && answers.length > 0 && (
        <>
          <div id="questionnaire-answers">
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 16 }}>
              <h4 style={{ marginBottom: 16, marginTop: 16 }}>Questionnaire Answers</h4>
              <Button type="primary" onClick={() => questionnaireSaveAsPDF('ref', referral.id)}>
                Save as PDF
              </Button>
            </div>
            <Descriptions bordered size="small" column={1}>
              {answers.map((answer, index) => {
                return (
                  <React.Fragment key={index}>
                    {answer.split('\n').map((v, idx) => {
                      if (v.trim() === '') return null;
                      if (v.split(':', 2).length !== 2) {
                        return (
                          <Descriptions.Item
                            key={`notes-${index}-${idx}`}
                            label={<span style={{ fontWeight: 800 }}>Notes</span>}
                          >
                            {v}
                          </Descriptions.Item>
                        );
                      }

                      const components = v.split(':');
                      const question = components.shift();
                      const answerText = components.join(':');
                      return (
                        <Descriptions.Item
                          key={`answer-${index}-${idx}`}
                          label={<span style={{ fontWeight: 800 }}>{question}</span>}
                        >
                          {answerText}
                        </Descriptions.Item>
                      );
                    })}
                  </React.Fragment>
                );
              })}
            </Descriptions>
          </div>
        </>
      )}
    </Col>
  );
};

const QuestionnaireAnswers: FC<{ sets: QuestionnaireAnswerSet[]; referral: ReferralData }> = ({ sets, referral }) => {
  return (
    <Col span={24}>
      {Array.isArray(sets) && sets.length > 0 && (
        <>
          <div id="questionnaire-answers">
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 16 }}>
              <h4 style={{ marginBottom: 16, marginTop: 16 }}>Questionnaire Answers</h4>
              <Button type="primary" onClick={() => questionnaireSaveAsPDF('ref', referral.id)}>
                Save as PDF
              </Button>
            </div>
            {sets.map(set => (
              <div key={set.id}>
                <h5 className="mt-4 mb-2 font-bold">{set.questionnaireName}</h5>
                <Descriptions bordered size="small" column={1}>
                  {set.questionnaireAnswers?.map(answer => (
                    <Descriptions.Item key={answer.id} label={<span style={{ fontWeight: 800 }}>{answer.text}</span>}>
                      {answer.value}
                    </Descriptions.Item>
                  ))}
                </Descriptions>
              </div>
            ))}
          </div>
        </>
      )}
    </Col>
  );
};
