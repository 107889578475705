import { Button, Col, Input, Row, Switch, Tree } from 'antd';
import React, { FC, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { IsOrgAdminOrBlockitAdmin } from '../../../components/IsOrgAdminOrBlockitAdmin';
import { deletePaginatedCacheItems, validateEmail } from '../../../helpers';
import { AppContext } from '../../core/contexts/AppContext';
import { useCreateUserInOrganizationMutation } from '../../../hooks';

const { TreeNode } = Tree;

interface Props {
  organizationLocations: {
    id: string;
    name: string;
    address1: string;
  }[];
}

export const UserForm: FC<Props> = ({ organizationLocations }) => {
  const { currentOrganization } = useContext(AppContext);
  const [createUserInOrganization] = useCreateUserInOrganizationMutation();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [isActive, setIsActive] = useState(!currentOrganization?.hasSsoStrict);
  const [selectedLocationIds, setSelectedLocationIds] = useState<string[]>([]);
  const [busy, setBusy] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const history = useHistory();

  if (!currentOrganization) {
    return <></>;
  }

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    event.preventDefault();

    if (!isAdmin && selectedLocationIds.length === 0) {
      setErrors(['User must be assigned to at least 1 location if they are not an admin.']);
      return;
    }

    if (name.length < 3) {
      setErrors(['Name must be at least 3 characters']);
      return;
    }

    if (email.length < 1) {
      setErrors(['Email Required']);
      return;
    }

    if (validateEmail(email.valueOf()) !== '') {
      setErrors(['Email address invalid']);
      return;
    }

    setBusy(true);

    const hasAllLocations = selectedLocationIds.length >= organizationLocations.length;

    try {
      const userLocationIdsToAdd = selectedLocationIds.filter(id => id !== 'allLocations');

      await createUserInOrganization({
        variables: {
          name,
          email,
          phone,
          isAdmin,
          isActive,
          organizationId: currentOrganization.id,
          hasAllLocations,
          userLocationIdsToAdd,
        },
        update: cache => deletePaginatedCacheItems(cache, 'PaginatedUsers'),
      });
      setBusy(false);
      setErrors([]);
      history.push(`/organizations/${currentOrganization.id}/users`);
    } catch (error) {
      const graphQLErrors = (error as { graphQLErrors?: { message: string }[] }).graphQLErrors;
      if (graphQLErrors && graphQLErrors.length > 0) {
        setErrors(graphQLErrors.map((gError: { message: string }) => gError.message));
      } else {
        setErrors(['Unknown network error, try again']);
      }
      setBusy(false);
    }
  };

  return (
    <Styles>
      <form>
        <Row gutter={24}>
          <Col span={12}>
            {errors.length > 0
              ? errors.map(e => (
                  <div style={{ color: '#c53030' }} key={e}>
                    {e}
                  </div>
                ))
              : null}

            <div>
              <label htmlFor="name">Name</label>
              <Input name="name" type="text" value={name} onChange={e => setName(e.target.value)} />
            </div>
            <br />

            <div>
              <label htmlFor="email">Email</label>
              <Input name="email" type="email" value={email} onChange={e => setEmail(e.target.value.toLowerCase())} />
            </div>
            <br />

            <div>
              <label htmlFor="phone">Phone</label>
              <Input name="phone" type="text" value={phone} onChange={e => setPhone(e.target.value)} />
            </div>
            <br />
            <IsOrgAdminOrBlockitAdmin>
              <>
                <div>
                  <label htmlFor="isActive">Active</label>
                  <Switch style={{ marginLeft: '5px' }} checked={isActive} onChange={checked => setIsActive(checked)} />
                </div>
                <br />

                <div>
                  <label htmlFor="isAdmin">Is this user an admin?</label>
                  <Switch style={{ marginLeft: '5px' }} checked={isAdmin} onChange={checked => setIsAdmin(checked)} />
                </div>
              </>
            </IsOrgAdminOrBlockitAdmin>
            <br />
            <Button type="primary" onClick={handleSubmit} loading={busy}>
              Add User
            </Button>
          </Col>
          <Col span={12}>
            <div>
              <label htmlFor="locations">Locations</label>
              <Tree
                checkable
                defaultExpandAll={true}
                onCheck={checkedLocations => {
                  if (Array.isArray(checkedLocations)) setSelectedLocationIds(checkedLocations);
                }}
                checkedKeys={selectedLocationIds}
              >
                <TreeNode title="All Locations" key="allLocations">
                  {organizationLocations.map(location => {
                    return <TreeNode title={location.name + ' | ' + location.address1} key={location.id} />;
                  })}
                </TreeNode>
              </Tree>
            </div>
          </Col>
        </Row>
      </form>
    </Styles>
  );
};

const Styles = styled.div`
  label {
    color: #718096;
    font-weight: 800;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.025em;
  }
`;
