import { Button, Col, Form, Icon, Input, message, Row } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import { defaultTheme } from '../../../themes/default';
import AntDesignStyleOverrides from '../../core/components/App/AntDesignStyleOverrides';
import { exchangeCredentials } from '..';
import { LoginToken } from '../loginToken';

const Styles = styled.div`
  background-color: ${props => props.theme.primaryColor};
  height: 100vh;
  padding-top: 4rem;

  .blockit-logo {
    margin-bottom: 2rem;
    width: 100%;
  }

  form {
    background-color: #ffffff;
    padding: 4rem 4rem 4rem 4rem;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.18);
  }

  @media only screen and (max-width: 576px) {
    padding-top: 2rem;
    form {
      padding: 2rem 2rem 2rem 2rem;
      box-shadow: 0 0 0;
    }
  }

  i {
    color: rgba(0, 0, 0, 0.25);
    margin-left: -8px;
  }

  .login-terms {
    margin-top: 2rem;
    color: white;
    text-align: justify;
    font-size: 0.75rem;
    line-height: 0.9rem;
    font-weight: 200;
    color: hsl(205, 0%, 90%);

    p:first-child {
      text-align: center;
    }
  }
`;

interface Props {
  form: WrappedFormUtils;
  isAuthenticated: boolean;

  location: {
    state?: {
      from: { pathname: string };
    };
  };
  handleAuthChange: (arg0: LoginToken) => void;
}

interface State {
  loading: boolean;
  accountLocked: boolean;
}

class Login extends Component<Props, State> {
  passwordInput = React.createRef<Input>();
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
      accountLocked: false,
    };
  }

  handleLoginError = (status: number, errorMessage: string): void => {
    this.props.form.setFieldsValue({ password: '' });
    if (this.passwordInput.current) this.passwordInput.current.focus();

    if (status === 401) {
      message.error('Your email or password is incorrect.');
    } else if (status === 403) {
      message.error(errorMessage);
    } else if (status === 413) {
      message.error(
        'You’ve entered your login credentials incorrectly 4 times. Your account will be locked for 24 hours upon the next incorrect attempt to login for account security.  Optionally, you may click forgot password and follow the instructions or contact support for assistance.'
      );
    } else if (status === 423) {
      this.setState({ accountLocked: true });

      message.error(
        'Your account has been locked for 24 hours due to 5 or more incorrect login attempts. You may contact support for assistance.'
      );
    } else {
      message.error('An error occurred, please try again.');
    }
  };

  handleLogin = async ({ email, password }: { email: string; password: string }): Promise<void> => {
    if (email.match('beaumont.org') || email.match('wellstreet.com')) {
      window.location.href = 'https://api.blockitnow.com/sso/auth/signin/beaumont';
    }

    const response = await exchangeCredentials(email, password);
    this.setState({ loading: false });

    if (response.err) {
      this.handleLoginError(response.val.status, response.val.message);
    } else {
      this.props.handleAuthChange(response.val);
    }
  };

  handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (err) {
        message.error('Please fix the form errors and try again.');
      } else {
        this.setState({ loading: true });
        this.handleLogin({ email: values.email, password: values.password });
      }
    });
  };

  render(): JSX.Element {
    const { getFieldDecorator } = this.props.form;
    const { isAuthenticated } = this.props;
    const { accountLocked } = this.state;
    const { from } = this.props.location.state || { from: { pathname: '/' } };

    if (isAuthenticated) {
      return <Redirect to={from} />;
    }

    return (
      <ThemeProvider theme={defaultTheme}>
        <AntDesignStyleOverrides>
          <Styles>
            <Row type="flex" justify="center" align="middle">
              <Col xs={10} sm={10} md={8} lg={6} xl={4}>
                <img src="/images/logo.png" alt="Blockit" className="blockit-logo" />
              </Col>
            </Row>
            <Row type="flex" justify="space-around" align="middle">
              <Col xs={24} sm={18} md={12} lg={12} xl={8}>
                <Form onSubmit={this.handleSubmit} className="login-form">
                  <Form.Item label="Email Address">
                    {getFieldDecorator('email', {
                      validateTrigger: 'onBlur',
                      rules: [
                        {
                          type: 'email',
                          message: 'must be a valid email',
                        },
                        {
                          required: true,
                          message: 'required',
                        },
                      ],
                    })(<Input prefix={<Icon type="user" />} placeholder="Email" />)}
                  </Form.Item>
                  <Form.Item label="Password">
                    {getFieldDecorator('password', {
                      rules: [{ required: true, message: 'required' }],
                    })(
                      <Input
                        ref={this.passwordInput}
                        prefix={<Icon type="lock" />}
                        type="password"
                        placeholder="Password"
                      />
                    )}
                    <a
                      href="https://api.blockitnow.com/users/reset_password"
                      style={{ float: 'right' }}
                      hidden={accountLocked}
                    >
                      Forgot password
                    </a>
                  </Form.Item>
                  <Button loading={this.state.loading} type="primary" htmlType="submit" block hidden={accountLocked}>
                    Login
                  </Button>
                </Form>
              </Col>
            </Row>
            <Row type="flex" justify="center" align="middle" className="login-terms">
              <Col xs={22} sm={18} md={12} lg={12} xl={8}>
                <p>© 2018-2020 Blockit, Inc. All Rights Reserved.</p>
              </Col>
            </Row>
          </Styles>
        </AntDesignStyleOverrides>
      </ThemeProvider>
    );
  }
}

export default Form.create<Props>({ name: 'Login' })(Login);
